<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        title="质检方案详情"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="" :column="2">
            <a-descriptions-item label="质检方案编码">{{ infoData?.code }}</a-descriptions-item>
            <a-descriptions-item label="质检方案名称">{{ infoData?.name }}</a-descriptions-item>
            <a-descriptions-item label="质检方案类型">{{ infoData?.typeZh }}</a-descriptions-item>
            <a-descriptions-item label="质检方案类别">
                {{ infoData?.categoryName }}
            </a-descriptions-item>
            <a-descriptions-item label="质检项数量">
                {{ infoData?.itemCount }}
            </a-descriptions-item>
            <a-descriptions-item label="质检方案附件" :span="2">
                <div class="filebox">
                    <a v-for="item in infoData?.files" :href="`${fileURL}/download/${item?.id}`">
                        {{ item?.fileName }}
                    </a>
                </div>
            </a-descriptions-item>
            <a-descriptions-item label="创建日期">{{ infoData?.createdTime }}</a-descriptions-item>
            <a-descriptions-item label="创建人">
                {{ infoData?.creatorDisplayName }}
            </a-descriptions-item>
            <a-descriptions-item label="上次变更时间">
                {{ infoData?.lastUpdateTime }}
            </a-descriptions-item>
            <a-descriptions-item label="变更人">
                {{ infoData?.lastUpdaterDisplayName }}
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs } from "vue";
import { apiCheckPlanDetail } from "@/api/qualityCheck";
import { fileURL } from "@/utils/config";

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId", "success"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            infoData: null,
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getSetIndexData = async id => {
            let res = await apiCheckPlanDetail(id);
            if (res.status === "SUCCESS") {
                state.infoData = res?.data?.editInfo;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getSetIndexData(newVal);
                }
            }
        );

        return {
            fileURL,

            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>

<style lang="less" scoped>
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
