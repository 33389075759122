import HttpRequest from "@/utils/request";

const axios = new HttpRequest();

// 质检方案
const apiCheckPlanList = data =>
    axios.request({ url: "/bas-qc-plan/find-page", method: "post", data });

const apiCheckPlanEnableList = data =>
    axios.request({ url: "/bas-qc-plan/find-published-page", method: "post", data });

const apiCheckPlanUpdate = data =>
    axios.request({ url: `/bas-qc-plan/${data?.id ? "update" : "save"}`, method: "post", data });

const apiCheckPlanUpdateIndexData = id =>
    axios.request({
        url: `/bas-qc-plan/get-add-or-edit-page-init-data${id ? "/" + id : ""}`,
        method: "get",
    });

const apiCheckPlanDelete = id =>
    axios.request({ url: `/bas-qc-plan/delete/${id}`, method: "post" });

const apiCheckPlanEnable = (id, enabled) =>
    axios.request({
        url: `/bas-qc-plan/${enabled ? "un-publish" : "publish"}/${id}`,
        method: "post",
    });

const apiCheckPlanDetail = id =>
    axios.request({ url: `/bas-qc-plan/get-config-page-init-data/${id}`, method: "get" });

const apiCheckPlanSet = data =>
    axios.request({ url: "/bas-qc-plan/save-plan-items", method: "post", data });

const apiCheckPlanIndexData = () =>
    axios.request({
        url: "/bas-qc-plan/get-search-page-init-data",
        method: "get",
    });

// 质检项
const apiCheckItemList = data =>
    axios.request({ url: "/bas-qc-item/find-page", method: "post", data });

const apiCheckItemPublishList = data =>
    axios.request({ url: "/bas-qc-item/find-published-page", method: "post", data });

const apiCheckItemUpdate = data =>
    axios.request({ url: `/bas-qc-item/${data?.id ? "update" : "save"}`, method: "post", data });

const apiCheckItemUpdateIndexData = id =>
    axios.request({
        url: `/bas-qc-item/get-add-or-edit-page-init-data${id ? "/" + id : ""}`,
        method: "get",
    });

const apiCheckItemDelete = id =>
    axios.request({ url: `/bas-qc-item/delete/${id}`, method: "post" });

const apiCheckItemEnable = (id, enabled) =>
    axios.request({
        url: `/bas-qc-item/${enabled ? "un-publish" : "publish"}/${id}`,
        method: "post",
    });

const apiCheckItemDetail = id =>
    axios.request({ url: `/bas-qc-item/get-deep-info/${id}`, method: "get" });

const apiCheckItemIndexData = () =>
    axios.request({
        url: "/bas-qc-item/get-add-or-edit-page-init-data",
        method: "get",
    });

// 检具
const apiCheckToolList = data =>
    axios.request({ url: "/bas-check-tool/find-page", method: "post", data });

const apiCheckToolEnableList = data =>
    axios.request({ url: "/bas-check-tool/find-enabled-page", method: "post", data });

const apiCheckToolDetail = id =>
    axios.request({ url: `/bas-check-tool/get-info/${id}`, method: "get" });

const apiCheckToolUpdate = data =>
    axios.request({ url: `/bas-check-tool/${data?.id ? "update" : "save"}`, method: "post", data });

const apiCheckToolUpdateIndexData = id =>
    axios.request({
        url: `/bas-check-tool/get-add-or-update-init-data${id ? "/" + id : ""}`,
        method: "get",
    });

const apiCheckToolEnable = id =>
    axios.request({ url: `/bas-check-tool/change-enabled/${id}`, method: "post" });

const apiCheckToolDelete = data =>
    axios.request({ url: "/bas-check-tool/delete-by-ids", method: "post", data });

const apiCheckToolIndexData = () =>
    axios.request({
        url: "/bas-check-tool/get-find-init-data",
        method: "get",
    });

// 物料质检组
const apiMaterialCheckTeamList = data =>
    axios.request({ url: "/bas-materials-qc-plan-group/find-page", method: "post", data });

const apiMaterialCheckTeamUpdate = data =>
    axios.request({
        url: `/bas-materials-qc-plan-group/${data?.id ? "update" : "save"}`,
        method: "post",
        data,
    });

const apiMaterialCheckTeamEnable = (id, enabled) =>
    axios.request({
        url: `/bas-materials-qc-plan-group/${enabled ? "disabled" : "enabled"}/${id}`,
        method: "post",
    });

const apiMaterialCheckTeamDelete = id =>
    axios.request({ url: `/bas-materials-qc-plan-group/delete/${id}`, method: "post" });

const apiMaterialCheckTeamDetail = id =>
    axios.request({ url: `/bas-materials-qc-plan-group/get-deep-info/${id}`, method: "get" });

// 送检单
const apiSendCheckOrderList = data =>
    axios.request({ url: "/em-inspection-request/find-page", method: "post", data });

const apiSendCheckOrderUpdate = data =>
    axios.request({
        url: "/em-inspection-request/save",
        method: "post",
        data,
    });

const apiSendCheckOrderSearchIndexData = () =>
    axios.request({
        url: "/em-inspection-request/get-search-page-init-data",
        method: "get",
    });

const apiSendCheckOrderUpdateIndexData = id =>
    axios.request({
        url: `/em-inspection-request/get-add-or-edit-page-init-data${id ? "/" + id : ""}`,
        method: "get",
    });

const apiSendCheckOrderDetail = id =>
    axios.request({ url: `/em-inspection-request/get-deep-info/${id}`, method: "get" });

const apiSendCheckOrderTerminated = data =>
    axios.request({ url: `/em-inspection-request/terminate`, method: "post", data });

// 检验单
const apiCheckOrderList = data =>
    axios.request({ url: "/em-quality-check-list/find-page", method: "post", data });

const apiCheckOrderSearchIndexData = () =>
    axios.request({
        url: "/em-quality-check-list/get-search-page-init-data",
        method: "get",
    });

const apiCheckOrderDetail = id =>
    axios.request({ url: `/em-quality-check-list/get-info/${id}`, method: "post" });

const apiCheckOrderTerminated = data =>
    axios.request({ url: `/em-quality-check-list/terminate`, method: "post", data });

// 集成工序检验单
const apiJCStepCheckOrderList = data =>
    axios.request({ url: "/jc-step-check-list/find-page", method: "post", data });

const apiJCStepCheckOrderSearchIndexData = () =>
    axios.request({
        url: "/jc-step-check-list/get-search-page-init-data",
        method: "get",
    });

const apiJCStepCheckOrderDetail = id =>
    axios.request({ url: `/jc-step-check-list/get-info/${id}`, method: "get" });

const apiJCStepCheckRecordDetail = id =>
    axios.request({ url: `/jc-step-check/get-info/${id}`, method: "get" });

const apiJCStepCheckOrderTerminated = data =>
    axios.request({ url: `/jc-step-check-list/terminate`, method: "post", data });

// 集成成品检验单
const apiJCProductCheckOrderList = data =>
    axios.request({ url: "/jc-product-quality-check-list/find-page", method: "post", data });

const apiJCProductCheckOrderSearchIndexData = () =>
    axios.request({
        url: "/jc-product-quality-check-list/get-search-page-init-data",
        method: "get",
    });

const apiJCProductCheckOrderDetail = id =>
    axios.request({ url: `/jc-product-quality-check-list/get-info/${id}`, method: "get" });

const apiJCProductCheckOrderTerminated = data =>
    axios.request({ url: `/jc-product-quality-check-list/terminate`, method: "post", data });

export {
    // 质检方案
    apiCheckPlanList,
    apiCheckPlanEnableList,
    apiCheckPlanUpdate,
    apiCheckPlanUpdateIndexData,
    apiCheckPlanDelete,
    apiCheckPlanEnable,
    apiCheckPlanDetail,
    apiCheckPlanSet,
    apiCheckPlanIndexData,
    // 质检项
    apiCheckItemList,
    apiCheckItemPublishList,
    apiCheckItemUpdate,
    apiCheckItemUpdateIndexData,
    apiCheckItemDelete,
    apiCheckItemEnable,
    apiCheckItemDetail,
    apiCheckItemIndexData,
    // 检具
    apiCheckToolList,
    apiCheckToolEnableList,
    apiCheckToolDetail,
    apiCheckToolUpdate,
    apiCheckToolUpdateIndexData,
    apiCheckToolEnable,
    apiCheckToolDelete,
    apiCheckToolIndexData,
    // 物料质检组
    apiMaterialCheckTeamList,
    apiMaterialCheckTeamUpdate,
    apiMaterialCheckTeamEnable,
    apiMaterialCheckTeamDelete,
    apiMaterialCheckTeamDetail,
    // 送检单
    apiSendCheckOrderList,
    apiSendCheckOrderUpdate,
    apiSendCheckOrderSearchIndexData,
    apiSendCheckOrderUpdateIndexData,
    apiSendCheckOrderDetail,
    apiSendCheckOrderTerminated,
    // 检验单
    apiCheckOrderList,
    apiCheckOrderSearchIndexData,
    apiCheckOrderDetail,
    apiCheckOrderTerminated,
    // 集成工序检验单
    apiJCStepCheckOrderList,
    apiJCStepCheckOrderSearchIndexData,
    apiJCStepCheckOrderDetail,
    apiJCStepCheckRecordDetail,
    apiJCStepCheckOrderTerminated,
    // 集成成品检验单
    apiJCProductCheckOrderList,
    apiJCProductCheckOrderSearchIndexData,
    apiJCProductCheckOrderDetail,
    apiJCProductCheckOrderTerminated,
};
