<template>
    <a-table :dataSource="deviceList" :columns="columns" bordered size="small">
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ record.key }}
            </template>
            <template v-if="column.dataIndex === 'operate'">
                <a @click="handleDelete(index)">删除</a>
            </template>
        </template>
    </a-table>
</template>
<script>
export default {
    props: {
        deviceList: {
            default: [],
        },
    },
    emits: ["onDeleteTool"],
    setup(props, { emit }) {
        const handleDelete = index => {
            emit("onDeleteTool", index);
        };
        return {
            columns: [
                {
                    title: "序号",
                    dataIndex: "index",
                    width: 60,
                    fixed: "left",
                },
                {
                    title: "检具编码",
                    dataIndex: "code",
                },
                {
                    title: "检具名称",
                    dataIndex: "name",
                },
                {
                    title: "检具状态",
                    dataIndex: "checkToolStatusZh",
                },
                {
                    title: "检具类别",
                    dataIndex: "categoryName",
                },
                {
                    title: "规格型号",
                    dataIndex: "spec",
                },
                {
                    title: "品牌",
                    dataIndex: "brand",
                },
                {
                    title: "出厂编号",
                    dataIndex: "factoryNumber",
                },
                {
                    title: "操作",
                    dataIndex: "operate",
                    fixed: "right",
                    width: 130,
                },
            ],
            handleDelete,
        };
    },
};
</script>
