<template>
    <CheckItemModal
        v-model:checkItemModalVisible="checkItemModalVisible"
        :processType="planInfo?.type"
        @select="onSelectCheckItem"
    />
    <ToolSelectModal
        v-model:toolSelectModalVisible="toolSelectModalVisible"
        :disableData="currentToolList"
        @onSelect="onSelectTool"
    />
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        :destroyOnClose="true"
        title="质检方案配置"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleSubmit">提交</a-button>
            </a-space>
        </template>
        <div class="border">
            <a-descriptions title="质检方案信息" :column="2">
                <a-descriptions-item label="质检方案编码">
                    {{ planInfo?.code }}
                </a-descriptions-item>
                <a-descriptions-item label="质检方案名称">
                    {{ planInfo?.name }}
                </a-descriptions-item>
                <a-descriptions-item label="质检方案类型">
                    {{ planInfo?.typeZh }}
                </a-descriptions-item>
                <a-descriptions-item label="质检方案类别">
                    {{ planInfo?.categoryName }}
                </a-descriptions-item>
            </a-descriptions>
        </div>
        <div class="border">
            <div class="header">
                <a-descriptions title="质检流程"></a-descriptions>
                <a-space direction="horizontal" :size="8">
                    <a-button @click="() => ((checkItemList = []), (currentItemIndex = 0))">
                        重置
                    </a-button>
                    <a-button type="primary" @click="() => (checkItemModalVisible = true)">
                        添加
                    </a-button>
                </a-space>
            </div>
            <div class="process-box">
                <template v-for="(item, index) in checkItemList" :key="index">
                    <div class="process-item">
                        <svg height="80" width="140">
                            <polygon
                                @click="() => (currentItemIndex = index)"
                                points="2,2 100,2 140,40 100,78 2,78"
                                :style="{
                                    fill: currentItemIndex == index ? '#80808033' : '#fff',
                                    stroke: 'black',
                                    strokeWidth: 1,
                                }"
                            />
                        </svg>
                        <div class="desc" @click="() => (currentItemIndex = index)">
                            <close-circle-outlined
                                class="close"
                                @click="e => handleDelete(index, e)"
                            />
                            <div>{{ item?.baseInfo?.name }}</div>
                            <div>
                                <caret-left-outlined
                                    class="arrow"
                                    :style="{ color: index == 0 ? 'gray' : 'black' }"
                                    @click="e => handleUp(index, e)"
                                />
                                <caret-right-outlined
                                    class="arrow"
                                    :style="{
                                        color:
                                            index == checkItemList?.length - 1 ? 'gray' : 'black',
                                    }"
                                    @click="e => handleDown(index, e)"
                                />
                            </div>
                        </div>
                        <plus-circle-outlined
                            class="arrow add"
                            v-if="index != checkItemList?.length - 1"
                        />
                    </div>
                </template>
            </div>

            <a-tabs>
                <a-tab-pane key="1" tab="质检项信息">
                    <div v-show="checkItemList?.length">
                        <a-descriptions title="">
                            <a-descriptions-item label="质检项编码">
                                {{ currentItem?.baseInfo?.code }}
                            </a-descriptions-item>
                            <a-descriptions-item label="质检项名称">
                                {{ currentItem?.baseInfo?.name }}
                            </a-descriptions-item>
                            <a-descriptions-item label="质检项属性">
                                {{ currentItem?.baseInfo?.typeZh }}
                                <a-checkbox
                                    v-show="currentItem?.baseInfo?.type == 'QUANTITATIVE'"
                                    style="margin-left: 20px"
                                    :checked="currentItem.systemJudgmentPassNg"
                                    @change="
                                        e => (currentItem.systemJudgmentPassNg = e.target.checked)
                                    "
                                >
                                    系统判定
                                </a-checkbox>
                            </a-descriptions-item>
                            <a-descriptions-item label="检验标准" :span="3">
                                <a-input
                                    :value="currentItem?.testingStandards"
                                    @change="e => (currentItem.testingStandards = e.target.value)"
                                />
                            </a-descriptions-item>
                        </a-descriptions>
                        <a-descriptions :column="2">
                            <a-descriptions-item label="标准工时">
                                <div style="width: 100%; display: flex">
                                    <a-input-number
                                        :value="currentItem?.workingHours"
                                        @change="value => (currentItem.workingHours = value)"
                                        :min="0"
                                        showCount
                                        style="width: 100%"
                                    />
                                    <Select
                                        :value="currentItem?.unit"
                                        @change="value => (currentItem.unit = value)"
                                        :disabled="true"
                                        style="margin-left: 10px; width: 80px; margin-right: 20px"
                                        :options="workingHourUnitOptions"
                                    />
                                </div>
                            </a-descriptions-item>

                            <a-descriptions-item label="操作方式">
                                <Select
                                    :value="currentItem?.operationMethod"
                                    @change="value => (currentItem.operationMethod = value)"
                                    style="margin-left: 10px; width: 100%"
                                    :options="typeOptions"
                                />
                            </a-descriptions-item>
                            <a-descriptions-item
                                label="结果值列表"
                                :span="3"
                                v-if="currentItem?.baseInfo?.type == 'QUANTITATIVE'"
                            >
                                <div style="width: 100%">
                                    <div class="search">
                                        <a-button
                                            type="primary"
                                            size="small"
                                            @click="handleAddResult"
                                            v-show="checkItemList?.length"
                                        >
                                            添加
                                        </a-button>
                                    </div>
                                    <a-table
                                        :dataSource="currentItem?.resultList"
                                        :columns="columns"
                                        :pagination="false"
                                        bordered
                                        size="small"
                                    >
                                        <template #bodyCell="{ column, record, index }">
                                            <template v-if="column.dataIndex === 'index'">
                                                {{ index + 1 }}
                                            </template>
                                            <template v-if="column.dataIndex === 'label'">
                                                <a-input v-model:value="record.label" />
                                            </template>
                                            <template v-if="column.dataIndex === 'standardValue'">
                                                <a-input-number
                                                    :disabled="!currentItem.systemJudgmentPassNg"
                                                    v-model:value="record.standardValue"
                                                    :min="0"
                                                    :precision="2"
                                                    style="width: 100%"
                                                />
                                            </template>
                                            <template v-if="column.dataIndex === 'tolerance'">
                                                <a-input-number
                                                    :disabled="!currentItem.systemJudgmentPassNg"
                                                    v-model:value="record.tolerance"
                                                    :min="0"
                                                    :max="record.standardValue"
                                                    :precision="2"
                                                    style="width: 100%"
                                                />
                                            </template>
                                            <template v-if="column.dataIndex === 'upperLimit'">
                                                <a-input
                                                    :value="
                                                        comLimit(
                                                            record.standardValue,
                                                            record.tolerance,
                                                            'upperLimit',
                                                            index
                                                        )
                                                    "
                                                    :disabled="true"
                                                    style="width: 100%"
                                                />
                                            </template>
                                            <template v-if="column.dataIndex === 'lowerLimit'">
                                                <a-input
                                                    :value="
                                                        comLimit(
                                                            record.standardValue,
                                                            record.tolerance,
                                                            'lowerLimit',
                                                            index
                                                        )
                                                    "
                                                    :disabled="true"
                                                    style="width: 100%"
                                                />
                                            </template>
                                            <template v-if="column.dataIndex === 'unit'">
                                                <a-input v-model:value="record.unit" />
                                            </template>
                                            <template v-if="column.dataIndex === 'operate'">
                                                <a @click="handleDeleteResultList(index)">删除</a>
                                            </template>
                                        </template>
                                    </a-table>
                                </div>
                            </a-descriptions-item>
                        </a-descriptions>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="检具列表">
                    <div class="search">
                        <a-button
                            type="primary"
                            size="small"
                            @click="toolSelectModalVisible = true"
                            v-show="checkItemList?.length"
                        >
                            添加
                        </a-button>
                    </div>
                    <ToolList
                        :deviceList="currentToolList"
                        @onDeleteTool="index => onDeleteTool(index)"
                    />
                </a-tab-pane>
            </a-tabs>
        </div>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiCheckPlanDetail, apiCheckPlanSet } from "@/api/qualityCheck";
import CheckItemModal from "@/components/CheckItemModal";
import ToolList from "./toolList";
import Select from "@/components/Select";
import ToolSelectModal from "@/components/ToolSelectModal";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { fileURL } from "@/utils/config";
import {
    CloseCircleOutlined,
    CaretLeftOutlined,
    CaretRightOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons-vue";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "结果名称",
        dataIndex: "label",
    },
    {
        title: "标准值",
        dataIndex: "standardValue",
    },
    {
        title: "公差",
        dataIndex: "tolerance",
    },
    {
        title: "上限",
        dataIndex: "upperLimit",
    },
    {
        title: "下限",
        dataIndex: "lowerLimit",
    },
    {
        title: "单位",
        dataIndex: "unit",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 130,
    },
];
export default defineComponent({
    props: ["setId"],
    emits: ["update:setId", "success"],
    components: {
        CheckItemModal,
        CaretLeftOutlined,
        CloseCircleOutlined,
        CaretRightOutlined,
        PlusCircleOutlined,
        ToolList,
        ToolSelectModal,
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            toolSelectModalVisible: false,
            workingHourUnitOptions: [
                { label: "分", value: "分" },
                { label: "时", value: "时" },
                { label: "天", value: "天" },
            ],
            typeOptions: computed(() => {
                if (state?.currentItem?.baseInfo?.type == "QUANTITATIVE") {
                    return [{ label: "独立检验", value: "SINGLE_CHECK" }];
                }
                return [
                    { label: "独立检验", value: "SINGLE_CHECK" },
                    { label: "合并检验", value: "MERGE_CHECK" },
                ];
            }),
            loading: false,
            planInfo: null,
            checkItemList: [],
            checkItemModalVisible: false,
            currentItemIndex: 0,
            currentItem: computed(() => state.checkItemList?.[state.currentItemIndex] || {}),
            currentResultList: computed(() =>
                state.currentItem?.resultList?.map((item, index) => ({
                    ...item,
                    key: index + 1,
                    index,
                }))
            ),
            currentToolList: computed(() => {
                const { checkItemList, currentItemIndex } = state;
                return checkItemList?.length
                    ? checkItemList[currentItemIndex]?.toolList?.map((item, index) => ({
                          ...item,
                          key: index + 1,
                          index,
                      }))
                    : [];
            }),
            comLimit: computed(() => {
                return (standardValue, tolerance, type, index) => {
                    let res;
                    if (standardValue == null || tolerance == null) {
                        res = null;
                        return null;
                    } else {
                        if (type == "lowerLimit") {
                            tolerance = -tolerance;
                        }
                        res = standardValue + tolerance;
                    }
                    state.currentItem.resultList[index][type] = res;
                    return res;
                };
            }),
        });

        const handleCancel = () => {
            state.planInfo = null;
            state.checkItemList = [];
            emit("update:setId");
        };

        const handleSubmit = async () => {
            const reqData = {
                planId: props.setId,
                items: [],
            };
            if (!state.checkItemList.length) return showMessage("info", "请添加质检项");
            for (let [index, item] of Object.entries(state.checkItemList)) {
                index = +index;
                const {
                    testingStandards,
                    systemJudgmentPassNg,
                    workingHours,
                    unit,
                    operationMethod,
                    resultList,
                } = item;
                for (let value of Object.values({
                    testingStandards,
                    workingHours,
                    unit,
                    operationMethod,
                })) {
                    if (value == null || checkValueIsAvailable(value, "spaceAll")) {
                        return showMessage(
                            "info",
                            `请填写第${index + 1}个质检项信息，不能全是空格`
                        );
                    }
                }
                if (item?.baseInfo?.type == "QUANTITATIVE") {
                    if (!resultList?.length)
                        return showMessage(
                            "info",
                            `请填写第${index + 1}个质检项结果值列表，不能全是空格`
                        );
                    for (let row of resultList) {
                        const [label, , , , , unit] = Object.values(row);
                        const checkValues = systemJudgmentPassNg
                            ? Object.values(row)
                            : [label, unit];
                        for (let rowValue of checkValues) {
                            if (rowValue == null || checkValueIsAvailable(rowValue, "spaceAll")) {
                                return showMessage(
                                    "info",
                                    `请填写第${index + 1}个质检项结果值列表，不能全是空格`
                                );
                            }
                            const { standardValue, tolerance } = row;
                            if (standardValue < tolerance) {
                                return showMessage(
                                    "info",
                                    `第${index + 1}个质检项标准值不能小于公差`
                                );
                            }
                        }
                    }
                }
                reqData.items.push({
                    itemId: item?.baseItemId,
                    testingStandards,
                    systemJudgmentPassNg: Boolean(systemJudgmentPassNg),
                    standardWorkingHours: {
                        workingHours,
                        unit,
                    },
                    operationMethod,
                    testingDetails: item?.resultList,
                    checkToolCodes: item?.toolList?.map(uu => uu?.code),
                });
            }
            let res = await apiCheckPlanSet(reqData);
            if (res.status === "SUCCESS") {
                emit("update:setId", null);
                emit("success");
                showMessage("success", "保存成功");
            }
        };

        const handleUp = (index, e) => {
            e.stopPropagation();
            if (index == 0) return showMessage("info", "已经是第一个");
            [state.checkItemList[index], state.checkItemList[index - 1]] = [
                state.checkItemList[index - 1],
                state.checkItemList[index],
            ];
        };

        const handleDown = (index, e) => {
            e.stopPropagation();
            if (index == state.checkItemList?.length - 1)
                return showMessage("info", "已经是最后一个");
            [state.checkItemList[index], state.checkItemList[index + 1]] = [
                state.checkItemList[index + 1],
                state.checkItemList[index],
            ];
        };

        const handleDelete = (index, e) => {
            e.stopPropagation();
            state.checkItemList.splice(index, 1);
            if (index == state.checkItemList?.length && state.checkItemList.length >= 1) {
                state.currentItemIndex = state.checkItemList?.length - 1;
            } else if (index != 0) {
                state.currentItemIndex--;
            }
        };

        const getSetIndexData = async roleId => {
            let res = await apiCheckPlanDetail(roleId);
            if (res.status === "SUCCESS") {
                const { editInfo } = res?.data;
                state.planInfo = editInfo || null;
                state.checkItemList = editInfo
                    ? editInfo?.items?.map((item, index) => ({
                          index,
                          baseItemId: item.itemId,
                          ...item,
                          baseInfo: item.basQcItem,
                          workingHours: item.standardWorkingHours?.workingHours,
                          unit: item.standardWorkingHours?.unit,
                          operationMethod: item?.operationMethod,
                          systemJudgmentPassNg: item?.systemJudgmentPassNg,
                          resultList:
                              item.testingDetails.map((item, index) => ({
                                  ...item,
                                  key: index + 1,
                                  index,
                              })) || [],
                          toolList: item.checkTools || [],
                      }))
                    : [];
            } else {
                state.planInfo = null;
                state.checkItemList = [];
            }
        };

        const handleAddResult = () => {
            state.checkItemList?.[state.currentItemIndex]?.resultList.push({
                label: undefined,
                standardValue: undefined,
                upperLimit: undefined,
                lowerLimit: undefined,
                unit: undefined,
            });
            const { resultList } = state.checkItemList?.[state.currentItemIndex];
            state.checkItemList[state.currentItemIndex].resultList = [...resultList]?.map(
                (item, index) => ({
                    ...item,
                    key: index + 1,
                    index,
                })
            );
        };

        const handleDeleteResultList = index => {
            if (state.checkItemList[state.currentItemIndex]) {
                state.checkItemList[state.currentItemIndex].resultList.splice(index, 1);
            }
        };

        const onSelectCheckItem = (rows, index) => {
            if (typeof index !== "boolean") {
                state.checkItemList[index - 1] = {
                    ...rows[0],
                    unit: "时",
                    baseItemId: rows[0].id,
                    index: index - 1,
                    baseInfo: rows[0],
                    resultList: [],
                    toolList: [],
                };
            } else {
                state.checkItemList = [
                    ...state.checkItemList,
                    ...rows.map((item, index) => ({
                        baseItemId: item.id,
                        unit: "时",
                        index: state.checkItemList?.length + index,
                        baseInfo: item,
                        resultList: [],
                        toolList: [],
                    })),
                ];
            }
        };

        const onDeleteTool = index => {
            const toolList = state.checkItemList[state.currentItemIndex].toolList;
            toolList.splice(index, 1);
            state.checkItemList[state.currentItemIndex].toolList = [...toolList]?.map(
                (item, index) => ({
                    ...item,
                    key: index + 1,
                    index,
                })
            );
        };

        const onSelectTool = value => {
            if (state.checkItemList[state.currentItemIndex]) {
                const toolList = state.checkItemList[state.currentItemIndex].toolList;
                state.checkItemList[state.currentItemIndex].toolList = [...toolList, ...value].map(
                    (item, index) => ({
                        ...item,
                        key: index + 1,
                        index,
                    })
                );
            }
        };

        watch(
            () => props.setId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getSetIndexData(newVal);
                }
            }
        );

        return {
            fileURL,
            ...toRefs(state),
            handleCancel,
            handleSubmit,
            handleUp,
            handleDelete,
            handleDown,
            onSelectCheckItem,
            onSelectTool,
            onDeleteTool,
            handleAddResult,
            handleDeleteResultList,
            columns,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.border {
    border: 1px solid;
    padding: 20px;
    margin-bottom: 20px;
    .header {
        display: flex;
        justify-content: space-between;
    }
}
.process-box {
    display: flex;
    overflow-x: scroll;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 5px;
    background: #80808017;
    height: 100px;
    .process-item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .desc {
            width: 125px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 5px;
            box-sizing: border-box;
            font-size: 13px;
            .close {
                position: absolute;
                top: 8px;
                right: 20px;
                cursor: pointer;
                font-size: 13px;
            }
            .arrow {
                cursor: pointer;
                margin-top: 5px;
                font-size: 15px;
            }
        }
    }
    .add {
        font-size: 18px;
        margin: 0 10px;
    }
}
.unit-info {
    position: relative;
    .search-btn {
        margin-bottom: 10px;
    }
}
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
.search {
    text-align: right;
    margin-bottom: 10px;
}
</style>
